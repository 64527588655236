<template>
  <Header/>
  <!--  <Projects/>-->
  <!--  <Footer/>-->
</template>

<script>
import Header from "@/components/Header";
import Projects from "@/components/Projects";
import Footer from "@/components/Footer";

export default {
  name: 'App',
  components: {
    Footer,
    Projects,
    Header
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Abel&display=swap');

::-webkit-scrollbar {
  width: 2em;
}

::-webkit-scrollbar-track {
  background-color: darkblue;
}

::-webkit-scrollbar-thumb {
  background-color: #4a8af0;
}

/*::-webkit-scrollbar-thumb:hover {*/
/*  background-color: #4a8af0;*/
/*}*/

* {
  font-family: 'Abel', sans-serif;
  margin: 0;
  padding: 0;
  user-select: none;
}
</style>
