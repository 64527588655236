<template>
  <div class="flex flex-col items-center rounded-xl m-5 bg-gray-200">
    <div class="photo">
      <img :src="image" alt="project photo" draggable="false" class="w-96	aspect-video rounded-t-md">
    </div>
    <div class="max-w-sm ">
      <h1 class="font-bold text-center pt-3">{{ title }}</h1>
      <p class="p-3">
        <slot/>
      </p>
    </div>
  </div>
</template>

<script>
import Skill from "@/components/Skill";
export default {
  name: "ProjectItem",
  components: {Skill},
  props: {
    title: String,
    description: String,
    image: String
  }
}
</script>
