<template>
  <div class="bg-blue-400">
    <h1 class="text-center text-4xl pb-10 text-white">Mijn Projecten</h1>
    <div class="inline-flex flex-wrap justify-center">
      <ProjectItem title="Eigen Framework" image="/assets/eigen-framework.png">
        Bij dit project heb ik een eigen Back-end Framework gemaakt in PHP. Hier heb ik enorm veel van geleerd en ik
        vond het leuk om te maken.
        <div class="flex justify-center">
          <div class="flex justify-center max-w-max p-1">
            <Skill name="PHP"/>
            <Skill name="Twig"/>
            <Skill name="RedBeans"/>
          </div>
        </div>
      </ProjectItem>
      <ProjectItem title="Flower Inc" image="/assets/FlowerInc.png">
        Hiervoor heb ik een klein CMS systeem gemaakt voor een bloemenhandel. Het project kan je op mijn GitHub account
        vinden onder pinned projects.
        <div class="flex justify-center">
          <div class="flex justify-center max-w-max p-1">
            <Skill name="Laravel"/>
            <Skill name="Tailwindcss"/>
          </div>
        </div>
      </ProjectItem>
      <ProjectItem title="Todo App" image="/assets/todo-app.png">
        Hier heb ik een Todo App gemaakt om de basics van React.js te leren. Ik heb hier een hoop kennis mee opgedaan
        die ik dagelijks gebruik bij andere projecten.
        <div class="flex justify-center">
          <div class="flex justify-center max-w-max p-1">
            <Skill name="React.js"/>
          </div>
        </div>
      </ProjectItem>
      <ProjectItem title="Discord Bots" image="/assets/discord.png">
        Ik houd me dagelijks bezig met Discord. Als ik tijd heb maak ik bots die dingen net wat makkelijker kunnen maken
        in een Discord server.
        <div class="flex justify-center">
          <div class="flex justify-center max-w-max p-1">
            <Skill name="Discord.js"/>
          </div>
        </div>
      </ProjectItem>
      <ProjectItem title="Prison Minecraft Plugin" image="/assets/prison-mc.png">
        In dit project heb ik voor een Twitch Streamer een Prison Break Minecraft Plugin gemaakt in Java met de spigot
        API.
        <div class="flex justify-center">
          <div class="flex flex-wrap">
            <Skill name="Spigot Api"/>
            <Skill name="Lucko/Helper"/>
            <Skill name="Java"/>
          </div>
        </div>
      </ProjectItem>
    </div>
  </div>
</template>

<script>
import ProjectItem from "@/components/ProjectItem";
import Skill from "@/components/Skill";

export default {
  name: "Projects",
  components: {
    Skill,
    ProjectItem
  }

}
</script>
