<template>
  <div class="bg-indigo-200 p-2 m-2 rounded-xl border-blue-400 border-2 hover:bg-blue-200">
    {{ name }}
  </div>
</template>

<script>
export default {
  name: "Skill",
  props: ['name']
}
</script>

<style scoped>

</style>
