<template>
  <div class="flex justify-center py-3 bg-blue-300">
    <h1 class="text-2xl text-white">© Justin Plein 2022</h1>
  </div>
</template>

<script>
export default {
  name: "Footer"
}
</script>
