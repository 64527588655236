<template>
  <div class="flex justify-center items-center h-screen bg-blue-400">
    <div class="flex items-center flex-col w-10/12">
      <div class="mb-10">
        <img src="https://media.giphy.com/media/fUYp0iOzQfC540KCs4/giphy.gif">

      </div>
      <div class="max-w-md">
        <div class="my-2 underline">
          <p>Hey! My site is currently in maintenance. Please come back later!</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Header"
}
</script>
